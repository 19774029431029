import React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";

import { H3, MapContainer } from 'styled';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const LoadableMap = loadable(() => import( /* webpackChunkName: "gatsby-MultiMarkermap" */ 'components/maps/MultiMarkerMap'));
const LoadableModalWrapper = loadable(() => import(/* webpackChunkName: "gatsby-ModalWrapper" */ 'components/HOC/ModalWrapper'));

const FindNearestAirportModal = ( { props, getAirfield, language }) => {
  const data = useStaticQuery(
    graphql`
      query flightSearchFormQuery2 {
        allPrismicUniversalProperties {
          edges {
            node {
              lang
              data {
                pop_up_map_search_box_placeholder
                map_marker_button
                marker_window_distance
                marker_window_airfield_code
                map_heading
                map_icon {
                  url
                }
              }
            }
          }
        }
        allPrismicFormFlightSearch {
          edges {
            node {
              lang
              data {
                find_neareast_map_text
              }
            }
          }
        }    
      }
    `)

  const sideBarProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  return (
    <LoadableModalWrapper   
      props={props}
      component={
        <MapContainer style={{ padding: '0 1em 1em 1em', minHeight: '400px', width: '100%' }}>
          <H3 
            fontSize="16px"
            fontWeight="400"
          >
            {sideBarProps.node.data.map_heading}
          </H3>
          <LoadableMap
            {...props}
            getAirfield={getAirfield}
            mapInputPlaceHolder={sideBarProps.node.data.pop_up_map_search_box_placeholder}
            mapMarkerText={sideBarProps.node.data.map_marker_button}
            isFlightSearch={true}
            mapIcon={sideBarProps.node.data.map_icon.url}
            marker_window_airfield_code={sideBarProps.node.data.marker_window_airfield_code}
            marker_window_distance={sideBarProps.node.data.marker_window_distance}
          />
        </MapContainer>
      }
    /> 
  )
}

FindNearestAirportModal.propTypes = {
  getAirfield: PropTypes.func,
  language: PropTypes.string,
  mapIcon: PropTypes.string,
  props: PropTypes.object,
  sideBarProps: PropTypes.object
}

export default FindNearestAirportModal;

